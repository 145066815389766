import { routeMap } from '/routeMap'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { useContrastingStyle } from '/machinery/StyleContext'
import { useParallaxText } from '/machinery/useParallax'
import { UppercaseHeadingAccentXxl } from '/features/buildingBlocks/UppercaseHeading'
import { CardHorizontal, CardVertical } from '/features/buildingBlocks/Card'
import { ContainerXl } from '/features/buildingBlocks/Container'
import { TextAccentSmCentered } from '/features/buildingBlocks/Text'
import { refToDataXLink } from '/machinery/tracking/pushToDataLayer'

import styleContext from '/cssGlobal/style-context.css'
import styles from './RetailOrOfficeJobs.css'

export function RetailOrOfficeJobs({ items, heading = undefined, description = undefined }) {
  const contrastingStyleContext = useContrastingStyle({ gray: styleContext.contextBlack })
  const { ref, style: parallaxAnimation } = useParallaxText({
    start: heading?.length * -1,
    end: 0
  })

  const Card = items.length === 1 ? CardHorizontal : CardVertical
  const hasMultipleItems = items.length > 1

  return (
    <div className={styles.component} {...{ ref }}>
      <ContainerXl>
        <div className={styles.layout}>
          {heading && (
            <UppercaseHeadingAccentXxl
              h='2'
              value={heading}
              animation={{ y: parallaxAnimation.y.to(x => `calc(${x} * 5dvh)`) }}
              layoutClassName={styles.headingLayout}
            />
          )}

          {description && (
            <TextAccentSmCentered
              text={description}
              animation={{ y: parallaxAnimation.y.to(x => `calc(${x} * 0.8 * 5dvh)`) }}
              layoutClassName={styles.descriptionLayout}
            />
          )}

          <div
            data-style-context={contrastingStyleContext}
            className={cx(styles.grid, hasMultipleItems && styles.hasMultipleItems)}
          >
            {items.map((x) => (
              <Card
                key={x._key}
                title={x.title}
                type={x.subHeader}
                label={x.link?.label}
                image={x.image}
                dataX={refToDataXLink(x.link?.ref)}
                href={x.link?.ref && determineDocumentPathSyncWithParams({
                  document: x.link.ref,
                  routeMap,
                  params: x.link.params
                })}
              />
            ))}
          </div>
        </div>
      </ContainerXl>
    </div>
  )
}
